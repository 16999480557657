import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/Layout"
import Seo from "../../components/Seo"
import ContentTypePagination from "../../components/ContentTypePagination"
import PostCategories from "../../components/PostCategories"
import FeaturedMedia from "../../components/FeaturedMedia"

export default ({ data }) => {
  const { nextPage, previousPage, page } = data
  const {
    title,
    content,
    featuredImage,
    categories,
    databaseId,
    seo,
    dateGmt,
    modifiedGmt,
    uri,
  } = page

  return (
    <Layout
      bodyClass={`post-template-default single single-post postid-${databaseId} single-format-standard wp-embed-responsive singular has-post-thumbnail has-single-pagination showing-comments footer-top-visible customize-support`}
    >
      <Seo
        seoData={seo}
        dateGmt={dateGmt}
        modifiedGmt={modifiedGmt}
        uri={uri}
      />

      <article
        id={`post-${databaseId}`}
        className={`post-${databaseId} single post type-post status-publish format-standard has-post-thumbnail hentry`}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-8 offset-md-2 mb-3 mb-lg-5">
              <header>
                <h1
                  className="entry-title"
                  dangerouslySetInnerHTML={{ __html: title }}
                />
                <div className="category-links">
                  <PostCategories categories={categories} />
                </div>
              </header>
            </div>
          </div>
          <div className="row mb-3 mb-lg-5" id="featured-image-container">
            <div className="col text-center">
              <FeaturedMedia image={featuredImage} />
            </div>
          </div>
          <div className="row">
            <div className="col-md-8 offset-md-2">
              <div
                className="entry-content"
                dangerouslySetInnerHTML={{ __html: content }}
              />
              <ContentTypePagination
                previousPage={previousPage}
                nextPage={nextPage}
                contentType="Post"
              />
            </div>
          </div>

        </div>
      </article>
    </Layout>
  )
}

export const query = graphql`
  query post($databaseId: Int!, $nextPage: Int, $previousPage: Int) {
    page: wpPost(databaseId: { eq: $databaseId }) {
      ...PostContent
    }
    nextPage: wpPost(databaseId: { eq: $nextPage }) {
      title
      uri
    }
    previousPage: wpPost(databaseId: { eq: $previousPage }) {
      title
      uri
    }
  }
`
