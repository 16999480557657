import React from "react"
import NonStretchedImage from "../utils/non-stretched-img"

const FeaturedMedia = ({ image }) => {
  image = image?.node
  if (!image?.remoteFile?.childImageSharp?.fluid) return null

  return (
    <div className="featured-media">
      <div className="featured-media-inner section-inner">
        <NonStretchedImage
          className="attachment-post-thumbnail size-post-thumbnail wp-post-image"
          fluid={image.remoteFile.childImageSharp.fluid}
        />
      </div>
    </div>
  )
}

export default FeaturedMedia
