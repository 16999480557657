import React from "react"
import { Link } from "gatsby"
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const ContentTypePagination = ({ previousPage, nextPage, contentType }) => {
  return (
    <nav className="mt-5 mb-5" aria-label={contentType} role="navigation">
      <hr className="styled-separator is-style-wide" aria-hidden="true" />

      <div className="blog-pagination mt-5">
        <div className="col-md-6">
          {previousPage && (
            <Link className="prev-page" to={previousPage.uri}>
              <span className="arrow" aria-hidden="true">
                <FontAwesomeIcon icon={faChevronLeft} />
              </span>
              <span className="title">
                <span
                  className="title-inner"
                  dangerouslySetInnerHTML={{ __html: previousPage.title }}
                />
              </span>
            </Link>
          )}
        </div>

        <div className="col-md-6">
          {nextPage && (
            <Link className="next-page" to={nextPage.uri}>
              <span className="title">
                <span
                  className="title-inner"
                  dangerouslySetInnerHTML={{ __html: nextPage.title }}
                />
              </span>
              <div className="arrow" aria-hidden="true">
                <FontAwesomeIcon icon={faChevronRight} />
              </div>
            </Link>
          )}
        </div>
      </div>
    </nav>
  )
}

export default ContentTypePagination
